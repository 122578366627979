import React, { FC, PropsWithChildren, ReactNode } from 'react';

import Layout from '~/components/Layout';
import menu from '~/constants/menu/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

interface TemplateAdminProps {
  secondary?: ReactNode;
  title?: string;
}

const TemplateAdmin: FC<PropsWithChildren<TemplateAdminProps & UserProps>> = ({
  children,
  secondary,
  title,
  user,
}) => (
  <Layout kind="admin" menu={menu} showNavigation user={user}>
    <div>
      {title && <h1 className="mb-6 text-xl font-bold">{title}</h1>}
      {undefined !== secondary && (
        <>
          {secondary}
          <hr className="my-6" />
        </>
      )}

      <div>{children}</div>
    </div>
  </Layout>
);
export default requireUser(TemplateAdmin);
